import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import { Container, Typography, createMuiTheme } from "@material-ui/core";
import { withStyles, ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "'M PLUS 1p'",
      "Quicksand",
      "sans- serif"
    ].join(","),
  },
});

class Layout extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteInfoQuery {
            site {
              siteMetadata {
                title
                description
                siteUrl
              }
            }
          }
        `}
        render={data => (
          <div id={this.props.id || "main"} className={this.props.isTop ? "index" : "article"}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{this.props.title} | {data.site.siteMetadata.title}</title>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
              <meta name="description" content={data.site.siteMetadata.description} />
              <meta name="keyword" content="art,artist,gold leaf,metal leaf,Japanese,guilding,contemporary art" />
              <meta property="og:url" content={data.site.siteMetadata.siteUrl + (this.props.location || "")} />
              <meta property="og:type" content={this.props.isTop ? "website" : "article"} />
              <meta property="og:title" content={this.props.title || data.site.siteMetadata.title} />
              <meta property="og:description" content={this.props.description || data.site.siteMetadata.description} />
              <meta property="og:site_name" content={data.site.siteMetadata.title} />
              <meta property="og:image" content={`${data.site.siteMetadata.siteUrl || "https://takeru-okado.com"}/images/top.jpg`} />
              <link rel="canonical" href="https://www.takeru-okado.com"></link>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:description" content={this.props.description || data.site.siteMetadata.description} />
              <meta name="twitter:title" content={this.props.title || data.site.siteMetadata.title} />
              <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl || "https://takeru-okado.com"}/images/top.jpg`} />
              <link href="https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&family=Quicksand&display=swap" rel="stylesheet"></link>
            </Helmet>
            <ThemeProvider theme={theme}>
              <Header siteMetadata={data.site.siteMetadata} title={this.props.title} />
              {!this.props.isTop && (
                <Container maxWidth="md" className={this.props.classes.container}>
                  {this.props.children}
                </Container>
              )}
              {this.props.isTop && (
                this.props.children
              )}
            </ThemeProvider>
          </div>
        )}
      />
    );
  }
}

const styles = () => ({
  container: {
    paddingTop: "100px",
  },
});

export default withStyles(styles)(Layout);