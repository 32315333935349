import React from "react";
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Button } from "@material-ui/core";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const useStyles = makeStyles(() => ({
  root: {},
  menuButton: {},
  title: {},
  appbar: {
    background: "rgba(0, 0, 0, 0.3)"
  },
  toolbar: {
    justifyContent: "space-between",
  },
  snsIcons: {
    display: "flex",
    flexDirection: "row",
  },
  link: {
    color: "white",
  },
  icon: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  iconBase: {
    position: "relative",
    minWidth: "53px",
    minHeight: "53px",
    borderRadius: "4px",
  },
  instagram: {
    background: "white",
    maskSize: "29px auto",
    maskPosition: "center",
    maskRepeat: "no-repeat",
    maskImage: `url("/images/glyph-logo_May2016.png")`,
  },
  facebook: {
    backgroundSize: "29px auto",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("/images/f_logo_RGB-White_58.png")`,
  }
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="transparent" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div id="menu-buttons">
            <Button color="inherit">
              <Link to="/" className={classes.link}>Top</Link>
            </Button>
            <Button color="inherit">
              <AnchorLink to="/works" className={classes.link}>Works</AnchorLink>
            </Button>
            <Button color="inherit">
              <Link to="/biography" className={classes.link}>Biography</Link>
            </Button>
            <IconButton color="inherit" className={classes.iconBase} href="https://www.instagram.com/takeru.okado/" target="_blank" rel="noopener noreferrer">
              <div id="instagram-icon" className={classes.icon + " " + classes.instagram} />
            </IconButton>
            <IconButton color="inherit" className={classes.iconBase} href="https://www.facebook.com/takeru.okado.10" target="_blank" rel="noopener noreferrer">
              <div id="facebook-icon" className={classes.icon + " " + classes.facebook} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
